// 
// taskboard.scss
//

.taskList {
    min-height: 40px;
    margin-bottom: 0;
  }
  
  .taskList li {
    background-color: $white;
    border: 1px solid $gray-300;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  
  .taskList li:last-of-type {
    margin-bottom: 0;
  }
  
  .taskList li .btn-sm {
    padding: 2px 8px;
    font-size: 12px;
  }
  
  .taskList .checkbox {
    margin-left: 20px;
    margin-top: 5px;
  }
  
  .task-placeholder {
    border: 1px dashed $gray-300 !important;
    background-color: $gray-100 !important;
    padding: 20px;
  }