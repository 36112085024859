// 
// bootstrap-tables.scss
//

.bootstrap-table {
    .table:not(.table-sm) {
        >tbody>tr>td,
        >tbody>tr>th,
        >tfoot>tr>td,
        >tfoot>tr>th,
        >thead>tr>td {
            padding: $table-cell-padding;
        }
    }
    .table {
        border-bottom: none;
    }
    .table>thead>tr>th {
        border-bottom: 2px solid transparent;
    }
}

table[data-toggle="table"] {
    display: none;
}

.fixed-table-pagination {
    .pagination-detail,
    div.pagination {
        margin-top: 20px;
        margin-bottom: 0;
    }
    .pagination {
        .page-link {
            border-radius: 30px !important;
            margin: 0 3px;
            border: none;
        }
    }
}

.fixed-table-container {
    border: none;
    tbody {
        td {
            border-left: none;
        }
    }
    thead {
        th {
            .th-inner {
                padding: $table-cell-padding;
            }
        }
    }
}

.fixed-table-toolbar {
    .fa {
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
    }
    .fa-toggle-down:before {
        content: "\f150";
    }
    .fa-toggle-up:before {
        content: "\f151";
    }
    .fa-refresh:before {
        content: "\f01e";
        font-weight: 900;
    }
    .fa-th-list:before {
        content: "\f0ca";
        font-weight: 900;
    }
}